// 应用权限分配弹窗
// sle
<template>
  <div id="JurisdictionView">
    <a-modal
      v-model:visible="visible"
      :maskClosable="false"
      title="应用权限"
      :footer="false"
      :width="1000"
      @cancel="closeView"
    >
      <a-spin :spinning="viewSpinning">
        <div class="showMain flex-centent">
          <div class="left">
            <div class="switchover flex-centent">
              <a-radio-group v-model:value="type" @change="typeChange">
                <a-radio-button :value="true">移动端</a-radio-button>
                <a-radio-button :value="false">PC端</a-radio-button>
              </a-radio-group>
            </div>
            <a-menu
              class="appList"
              mode="inline"
              v-model:selectedKeys="appSelectKeys"
            >
              <a-menu-item
                v-for="item in appList"
                :key="item.id"
                @click="appChange(item)"
              >
                <img :src="item.applicationIcon" class="img" />
                <span></span>
                {{ item.applicationName }}{{ item.started ? "" : "(关闭)" }}
              </a-menu-item>
            </a-menu>
          </div>
          <div class="right flex-column">
            <div class="top flex-row">
              <div class="container flex-row justify-center">
                <span v-if="appNotIbps">人员</span>
                <span v-if="!appNotIbps">岗位</span>
                <div class="button">
                  <!-- <a-button
                    v-if="appNotIbps"
                    @click="addPost"
                    :disabled="!disabled"
                    :title="!disabled == true ? '暂无权限' : ''"
                    >添加角色</a-button
                  > -->
                  <a-button
                    v-if="!appNotIbps"
                    @click="addPost"
                    :disabled="!disabled"
                    :title="!disabled == true ? '暂无权限' : ''"
                    >添加岗位</a-button
                  >
                </div>
              </div>
              <div class="container flex-row justify-center">
                <span>菜单</span>
                <div class="button">
                  <a-button @click="saveConfirm">保存</a-button>
                </div>
              </div>
            </div>
            <div class="content flex-row justify-between">
              <div class="container flex-column">
                <a-radio-group
                  class="post flex-column"
                  v-model:value="userSelectId"
                  @change="postChange"
                  v-if="appNotIbps"
                >
                  <a-radio
                    class="item flex-row align-center"
                    v-for="item in userList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label}}
                  </a-radio>
                </a-radio-group>
                <a-radio-group
                  class="post flex-column"
                  v-model:value="postSelectId"
                  @change="postChange"
                  v-else
                >
                  <a-radio
                    class="item flex-row align-center"
                    v-for="item in postList"
                    :key="item.linkKey"
                    :value="item.linkKey"
                  >
                    {{ item.name}}
                    <a class="label" @click.prevent @click="watchPerson(item)"
                      >查看</a
                    >
                  </a-radio>
                </a-radio-group>
              </div>
              <a-modal
                :title="currentPost.name + ' 下的员工'"
                :footer="false"
                :width="400"
                v-model:visible="employeeVisible"
              >
                <a-spin :spinning="employeeSpin">
                  <div v-for="employee in employeeList" :key="employee.id">
                    <div style="margin: 0px 0px 15px 0px">
                      <a-avatar
                        size="large"
                        :src="employee.avatarUrl"
                      ></a-avatar>
                      {{
                        employee.employeeName +
                        " (" +
                        employee.phoneNumber +
                        ")"
                      }}
                    </div>
                  </div>
                  <div>
                    <a-pagination
                      v-model:current="employeePagination"
                      :total="employeePageCount"
                      v-model:pageSize="employeePageSize"
                      @change="watchPerson(currentPost)"
                    />
                  </div>
                </a-spin>
              </a-modal>
              <div class="line-h"></div>
              <div class="container flex-column">
                <a-checkbox
                  v-if="menuList.length !== 0"
                  :checked="checkAll"
                  @change="onCheckAllChange"
                >
                  全选
                </a-checkbox>
                <a-checkbox-group
                  class="menu flex-column jurisdictionView-checkbox-group"
                  v-model:value="menuSelectList"
                  :options="menuList"
                  @change="onAppChange"
                >
                  <!-- <template #label="{ value }">
                    <span style="color: red">{{ value }}</span>
                  </template> -->
                </a-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </a-spin>

      <postDuty-edit-view
        ref="postDuty"
        @postDutyEditBeforeReload="getPostList"
      />
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { isEmpty, addressBookJurisdiction } from '@/assets/common.js'
import api from '@/api/API'
import PostDutyEditView from './../../AddressBook/InteriorLinkMan/PostDuty/PostDutyEditView.vue'

export default defineComponent({
  emits: ['postDutyEditBeforeReload'],
  components: {
    'postDuty-edit-view': PostDutyEditView,
  },
  data () {
    return {
      disabled: false,
      title: '暂无权限',
      type: true, // 菜单类型
      checkAll: false,
      selectAllList: [],
      viewSpinning: false, // 是否加载
      visible: false, // 弹窗是否显示
      appList: [], // 应用列表
      appSelectKeys: [], // 当前选中的应用
      appSelectId: null, // 当前选中的应用id
      appCode: null, // 当前选中的应用Code
      appNotIbps: false, // 当前选中的应用是否为非低代码
      postList: [], // 岗位列表
      roleList: [], // 角色列表
      userList: [], // 员工列表
      postSelectId: null, // 当前选中的岗位
      roleSelect: null, // 当前选中的角色
      userSelectId: null, // 当前选中的员工
      menuList: [], // 菜单列表
      menuSelectList: [], // 选中的菜单
      currentPost: {
        name: '',
      }, // 当前选中查看的岗位
      employeeSpin: false, // 员工页面是否加载
      employeeVisible: false, // 员工弹窗是否可见
      employeeList: [], // 岗位下员工列表
      employeePageSize: 6, // 岗位下员工分页大小
      employeePagination: 1, // 岗位下员工当前页数
      employeePageCount: 0, // 总页数
    }
  },
  created () {
    this.getJurisdiction()
  },
  methods: {
    // 获取权限
    getJurisdiction () {
      this.disabled = addressBookJurisdiction()
    },
    // 全选
    onCheckAllChange (e) {
      this.selectAllList = []
      this.menuList.forEach(element => {
        this.selectAllList.push(element.value)
      })
      this.menuSelectList = e.target.checked ? this.selectAllList : []
      this.checkAll = e.target.checked
    },
    // 单选
    onAppChange (checkedList) {
      this.checkAll = checkedList.length === this.menuList.length
    },
    // 编辑权限，呼出窗口
    viewShow (e, list) {
      this.viewSpinning = false
      this.visible = true
      this.appList = list
      this.appChange(e)
    },
    // 关闭编辑窗口
    closeView () {
      this.visible = false
      this.appSelectKeys = []
    },
    // 菜单类型变更
    typeChange (e) {
      this.getPostList()
      this.getMenuList()
      this.postSelectId = null
      this.roleSelect = null
      this.userSelectId = null
      this.menuList = []
      this.menuSelectList = []
      this.checkAll = false
    },
    // 应用变更事件
    appChange (e) {
      this.appSelectKeys = [e.id]
      this.appSelectId = this.appList.find(item => item.id === this.appSelectKeys[0]).applicationId
      this.appCode = this.appList.find(item => item.id === this.appSelectKeys[0]).applicationCode
      this.appNotIbps = this.appList.find(item => item.id === this.appSelectKeys[0]).isNotIbps
      this.getPostList()
      this.getMenuList()
      this.postSelectId = null
      this.roleSelect = null
      this.userSelectId = null
      this.menuList = []
      this.checkAll = false
      this.menuSelectList = []
    },
    // 岗位变更事件
    postChange (e) {
      if (this.appNotIbps) {
        // this.roleSelect = e.target.value
        this.userSelectId = e.target.value
      } else {
        this.postSelectId = e.target.value
      }
      this.getPostMenuList()
      this.getMenuList()
    },
    // 加载指定应用的岗位数据
    getPostList () {
      this.viewSpinning = true
      if (this.appNotIbps) {
        // 非低代码应用的场合，展示人员列表而非岗位
        api
          .get('/api/identity/users/GetUserAntCheckBoxList', {
            params: {
              filtAdmin: true,
            },
          })
          .then(({ data }) => {
            this.viewSpinning = false
            // for (var i = 0; i < data.items.length; i++) {
            //   // 不展示admin人员
            //   if (data.items[i].name === 'admin') {
            //     data.items.splice(i, 1)
            //   }
            // }
            this.userList = data
          })
          .catch(err => {
            this.viewSpinning = false
            // this.$message.error('获取岗位数据失败')
            console.log(err)
          })
      } else {
        api
          .get('/api/app/employee-post/application-post-list/' + this.appSelectId, {
            params: {
              appId: this.appSelectId,
              isAPPMenu: this.type,
            },
          })
          .then(({ data }) => {
            this.viewSpinning = false
            this.postList = data
          })
          .catch(err => {
            this.viewSpinning = false
            // this.$message.error('获取岗位数据失败')
            console.log(err)
          })
      }
    },
    // 加载指定应用的菜单数据
    getMenuList () {
      this.viewSpinning = true
      api
        .get('/api/app/post-menu/menu-by-app-id-and-type/' + this.appSelectId, {
          params: {
            appId: this.appSelectId,
            isAPPMenu: this.type,
          },
        })
        .then(({ data }) => {
          this.menuList = data
          this.viewSpinning = false
        })
        .catch(err => {
          this.viewSpinning = false
          // this.$message.error('获取菜单数据失败')
          console.log(err)
        })
    },
    // 加载指定应用，指定岗位的菜单数据
    getPostMenuList () {
      this.viewSpinning = true
      // 非低代码的情形，加载对应权限菜单的数据
      if (this.appNotIbps) {
        api
          .get('/api/permission-management/permissions/GetListByRoleNameAndCode', {
            params: {
              providerKey: this.userSelectId,
              appCode: this.appCode,
            },
          })
          .then(({ data }) => {
          // this.viewSpinning = false
            this.menuSelectList = data.checkList
            if (this.menuSelectList.length === 0 && this.appNotIbps) {
              this.menuSelectList = []
            }
            if (this.menuList.length === this.menuSelectList.length) {
              this.checkAll = true
            } else {
              this.checkAll = false
            }
            if (this.menuList.length === 0 && this.menuSelectList.length === 0) {
              this.checkAll = false
            }
          })
          .catch(err => {
            this.viewSpinning = false
            // this.$message.error('获取已分配菜单失败')
            console.log(err)
          })
      } else {
        api
          .get('/api/app/post-menu/post-menu-list-by-app-id-and-post-id', {
            params: {
              appId: this.appSelectId,
              postId: this.postSelectId,
              isAPPMenu: this.type,
            },
          })
          .then(({ data }) => {
          // this.viewSpinning = false
            this.menuSelectList = data
            if (this.menuSelectList.length === 0 && this.appNotIbps) {
              this.menuSelectList = []
            }
            if (this.menuList.length === this.menuSelectList.length) {
              this.checkAll = true
            } else {
              this.checkAll = false
            }
            if (this.menuList.length === 0 && this.menuSelectList.length === 0) {
              this.checkAll = false
            }
          })
          .catch(err => {
            this.viewSpinning = false
            // this.$message.error('获取已分配菜单失败')
            console.log(err)
          })
      }
    },
    // 查看岗位下的人
    watchPerson (item) {
      this.currentPost = item
      this.employeeSpin = true
      this.employeeVisible = true
      api
        .get('/api/app/employee/employee-list-by-post-id', {
          params: {
            input: item.linkKey,
            pageSize: this.employeePageSize,
            pagination: this.employeePagination,
          },
        })
        .then(({ data }) => {
          this.employeeSpin = false
          this.employeeList = data.list
          this.employeePageCount = data.pageCount
        })
        .catch(err => {
          this.employeeSpin = false
          // this.$message.error('获取已分配菜单失败')
          console.log(err)
        })
    },
    // 添加岗位
    addPost () {
      this.$refs.postDuty.addPostDuty()
    },
    // 保存
    saveConfirm () {
      if (!this.appNotIbps && isEmpty(this.postSelectId)) {
        this.$message.error('请先选择岗位')
        return
      }
      if (this.appNotIbps && isEmpty(this.userSelectId)) {
        this.$message.error('请先选择人员')
        return
      }

      this.viewSpinning = true
      // 非低代码的情形，调用保存权限的接口
      if (this.appNotIbps) {
        const obj = {
          appCode: this.appCode,
          permissionList: this.menuSelectList,
          linkKey: this.userSelectId,
          byUser: true,
        }
        api
          .post('/api/identity/roles/EditRolePermission', obj)
          .then(({ data }) => {
            this.viewSpinning = false
            this.$message.success('保存成功')
            this.getPostList()
          })
          .catch(err => {
            this.viewSpinning = false
            console.log(err)
          })
      } else {
        const obj = {
          applicationId: this.appSelectId,
          isAPPMenu: this.type,
          postId: this.postSelectId,
          menuList: this.menuSelectList,
        }
        api
          .post('/api/app/post-menu/application-post-menu-allocation', obj)
          .then(({ data }) => {
            this.viewSpinning = false
            this.$message.success('保存成功')
            this.getPostList()
          })
          .catch(err => {
            this.viewSpinning = false
            console.log(err)
          })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.showMain {
  width: 100%;
  .left {
    height: 450px;
    width: 200px;
    overflow: auto;
    .appList {
      height: 400px;
    }
    .switchover {
      height: 50px;
    }
    .img {
      height: 20px;
      widows: 20px;
    }
  }
  .right {
    width: 750px;
    height: 450px;
    .top {
      background: #f8f8f8;
      height: 50px;
      line-height: 50px;
      .container {
        width: 49%;
        .button {
          position: relative;
          left: 110px;
        }
      }
    }
    .content {
      height: 400px;
      .container {
        padding: 10px 20px;
        width: 49%;
        .post {
          overflow: auto;
          .item {
            padding: 15px;
            border-bottom: 1px solid #dddddd;
            .label {
              position: absolute;
              right: 0px;
            }
          }
        }
        .menu {
          overflow: auto;
        }
      }
      .line-h {
        width: 0px;
        border-left: 1px solid #dddddd;
      }
    }
  }
}
</style>

<style>
.jurisdictionView-checkbox-group label {
  padding: 15px !important;
  border-bottom: 1px solid #dddddd;
}
</style>
