// 岗位新增，编辑弹窗
// sle
<template>
  <div id="PostDutyEditView">
    <a-modal
      :confirmLoading="spinning"
      v-model:visible="visible"
      :maskClosable="false"
      :title="title"
      ok-text="保存"
      cancel-text="取消"
      @ok="editDutyViewSubit"
      @cancel="closepostDutyView"
    >
      <a-spin :spinning="spinning">
        <a-form
          :model="obj"
          ref="formRef"
          :rules="rules"
          autocomplete="off"
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 14 }"
        >
          <a-form-item label="岗位名称" required name="employeePostName">
            <a-input
              v-model:value="obj.employeePostName"
              required
              :maxlength="15"
            />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'

export default defineComponent({
  emits: ['postDutyEditBeforeReload'],
  data () {
    return {
      buttonVisible: false, // 编辑按钮是否显示
      spinning: false, // 是否加载
      visible: false, // 弹窗是否显示
      add: false, // 是否新增操作
      title: '', // 弹窗标题
      obj: {
        id: '',
        employeePostName: '', // 岗位名称
      },
      rules: {
        employeePostName: [
          {
            required: true,
            message: '请输入岗位名称',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '长度请控制在30以内',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
    }
  },
  methods: {
    // 添加岗位按钮，呼出添加岗位窗口
    addPostDuty () {
      this.visible = true
      this.title = '添加岗位'
      this.add = true
    },
    // 编辑岗位按钮，呼出编辑岗位窗口
    editPostDuty (id) {
      this.getPostDutyData(id)
    },
    // 获取选中岗位的详情数据
    getPostDutyData (id) {
      this.spinning = true
      api
        .get('/api/app/employee-post/post', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.obj = data
          this.visible = true
          this.title = '编辑'
          this.add = false
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取岗位信息失败')
          console.log(err)
        })
    },
    // 取消，关闭岗位编辑窗口
    closepostDutyView () {
      this.visible = false
      this.title = ''
      this.add = false
      this.$refs.formRef.resetFields()
      this.obj = {}
    },
    // 确认添加、编辑岗位操作
    editDutyViewSubit () {
      this.$refs.formRef
        .validate()
        .then(() => {
          const add = this.add
          this.spinning = true
          const name = this.obj.employeePostName
          const id = this.obj.id
          api
            .post('/api/app/employee-post/or-update-post', {
              employeePostName: name,
              id: add ? null : id,
              concurrencyStamp: add ? null : this.obj.concurrencyStamp,
            })
            .then(({ data }) => {
              this.$message.success('操作成功')
              this.closepostDutyView()
              this.$emit('postDutyEditBeforeReload', name, id)
              this.spinning = false
            })
            .catch(err => {
              this.spinning = false
              // this.$message.error('操作失败')
              console.log(err)
            })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  },
})
</script>
